import { Button } from "@/components/ui/button";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import getProviderMarkings from "@/lib/markings/getProviderMarkings";
import signLicensingPDF from "@/lib/sign-pdf";
import { trpc } from "@/lib/trpc";
import { formatFullDate, getUserMembershipFromUser } from "@/lib/utils";
import { FileIcon, ImageIcon } from "lucide-react";

export default function ProviderAssets() {
  const currentUser = useCurrentUserContext();
  const currentUserMembership = getUserMembershipFromUser(currentUser);
  if (!currentUserMembership) return null;

  const {
    isLoading,
    isError,
    data: orgData,
  } = trpc.getOrgById.useQuery(currentUserMembership.org_id);
  if (orgData && !orgData.provider_details) {
    throw new Error("Provider details not found");
  }
  const standardAgreementQuery =
    trpc.retrieveLicensingAgreementAcceptance.useQuery(
      currentUserMembership.org_id
    );

  function getLicenseRenewalDate() {
    if (!orgData?.provider_details) return null;
    const startDate = new Date(orgData.provider_details.created_at);

    return formatFullDate(startDate, false);
  }
  return isLoading ? (
    <div>Loading...</div>
  ) : isError || !orgData ? (
    <div>Error</div>
  ) : (
    <div className="max-w-xl">
      <dd className="mt-2 text-sm">
        <ul
          role="list"
          className="divide-y divide-gray-100 rounded-lg border border-gray-200"
        >
          <li className="py-4 pl-4 pr-5 text-sm leading-6 font-medium">
            License Number: {orgData.provider_details?.provider_id}
          </li>
          <li className="py-4 pl-4 pr-5 text-sm leading-6 font-medium">
            Annual License Renewal Date: {getLicenseRenewalDate()}
          </li>
          <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
            <div className="flex w-0 flex-1 items-center">
              <FileIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <div className="ml-4 flex min-w-0 flex-1 gap-2">
                <span className="truncate font-medium">
                  Your Provider Licensing Agreement
                </span>
              </div>
            </div>
            <div className="ml-4 flex-shrink-0">
              {orgData.provider_details?.custom_license_agreement ? (
                <Button asChild variant="ghost">
                  <a
                    href={
                      orgData.provider_details.custom_license_agreement
                        .file_details.cloudinary_url
                    }
                    target="_blank"
                  >
                    Download
                  </a>
                </Button>
              ) : (
                <Button
                  variant="ghost"
                  onClick={async () =>
                    await signLicensingPDF(standardAgreementQuery.data!)
                  }
                >
                  Download
                </Button>
              )}
            </div>
          </li>
          <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
            <div className="flex w-0 flex-1 items-center">
              <ImageIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <div className="ml-4 flex min-w-0 flex-1 gap-2">
                <span className="truncate font-medium">
                  Your Provider Markings
                </span>
              </div>
            </div>
            <div className="ml-4 flex-shrink-0">
              <Button
                variant="ghost"
                disabled={!orgData.provider_details?.provider_id}
                onClick={() =>
                  getProviderMarkings(orgData.provider_details!.provider_id)
                }
              >
                Download
              </Button>
            </div>
          </li>
        </ul>
      </dd>
    </div>
  );
}
